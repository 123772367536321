import { gql } from '@apollo/client';
import { REGION_FRAGMENT } from 'modules/locations/graphql/fragments';

export const PRODUCT_FRAGMENT = gql`
  fragment Product on ProductOutput {
    anticoagulant
    createdAt
    id
    shortId
    status
    storageRequirements
    updatedAt
    value
    woocommerceProductId
    woocommerceCategoryId
    woocommerceOrderId
    expirationDate
    published
    publishable
    regionPreference {
      ...Region
    }
  }
  ${REGION_FRAGMENT}
`;

export const DISCARDED_PRODUCT_DATA_FRAGMENT = gql`
  fragment DiscardedProductData on DiscardedProductDataOutput {
    processingType
    reason
    notes
  }
`;

export const PRODUCT_PROCESSOR_FRAGMENT = gql`
  fragment ProductProcessor on ProductProcessorOutput {
    id
    firstName
    lastName
  }
`;
