import { Col, Row } from 'antd';
import {
  ControlledCheckbox,
  ControlledRadioGroup,
  ControlledSelect,
  ControlledTextArea,
  FormItem,
  Radio,
} from 'modules/common/components';
import UserSelect from 'modules/user/components/UserSelect';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProcessingProductType, ReasonDiscardProduct, UserRole } from 'types.d';
import { downcaseFirstLetter } from 'utils/downcaseFirstLetter';

import styles from './styles.module.scss';

import { CreateProductSchema } from '../../schema';

type PropTypes = {
  productRegionPreferenceId?: string;
};

const DiscardedSection: FC<PropTypes> = ({ productRegionPreferenceId }) => {
  const { control, watch, formState } = useFormContext<CreateProductSchema>();
  const { t } = useTranslation('products.CreateProductModal');
  const isDiscarded = watch('discarded');
  const discardedReason = watch('discardedReason');
  const isOtherDiscardReson = discardedReason === ReasonDiscardProduct.Other;
  const formErrors = formState.errors;
  return (
    <Col span={24}>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <ControlledCheckbox controlled={{ control, name: 'discarded' }}>
            {t('discarded')}
          </ControlledCheckbox>
        </Col>
        {isDiscarded && (
          <>
            <Col span={24}>
              <FormItem
                validateStatus={formErrors.processingType ? 'error' : undefined}
                label={t('processing')}
              >
                <ControlledRadioGroup
                  controlled={{
                    control,
                    name: 'processingType',
                  }}
                >
                  <Radio value={ProcessingProductType.Sameday}>
                    {t('samedayProcessing')}
                  </Radio>
                  <Radio value={ProcessingProductType.Nextday}>
                    {t('nextdayProcessing')}
                  </Radio>
                </ControlledRadioGroup>
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                validateStatus={formErrors.processorId ? 'error' : undefined}
                label={t('processor')}
              >
                <UserSelect
                  userRoles={[
                    UserRole.Admin,
                    UserRole.CollectionTeam,
                    UserRole.InventoryTeam,
                    UserRole.MedicalDirector,
                  ]}
                  productRegionPreferenceId={productRegionPreferenceId}
                  controlled={{ control, name: 'processorId' }}
                  placeholder={t('selectProcessor')}
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                validateStatus={
                  formErrors.discardedReason ? 'error' : undefined
                }
                label={t('discardReason')}
              >
                <ControlledSelect
                  placeholder={t('selectDiscardReason')}
                  controlled={{ control, name: 'discardedReason' }}
                  options={Object.values(ReasonDiscardProduct).map((reason) => {
                    return {
                      label: t(downcaseFirstLetter(reason)),
                      value: reason,
                    };
                  })}
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col>
            {isOtherDiscardReson && (
              <Col span={24}>
                <FormItem
                  validateStatus={
                    formErrors.discardReasonDetails ? 'error' : undefined
                  }
                  label={t('discardReasonDetails')}
                >
                  <ControlledTextArea
                    controlled={{ control, name: 'discardReasonDetails' }}
                    placeholder={t('enterFreeText')}
                    maxLength={500}
                    size="large"
                    className={styles.discardReasonDetails}
                  />
                </FormItem>
              </Col>
            )}
          </>
        )}
      </Row>
    </Col>
  );
};

export default DiscardedSection;
