import { Col, message, Row } from 'antd';
import config from 'config';
import { format } from 'date-fns';
import { Button } from 'modules/common/components';
import { FC } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { ProductReportOutput, useGetProductReportsLazyQuery } from 'types.d';

import { donorReportHeaders } from './headers';

const ProductReports: FC = () => {
  const { t } = useTranslation('reports.ProductReports');
  const fileName = `products_${format(new Date(), config.SHORT_DATE_FORMAT)}`;
  const [getProductReport, getProductReportQuery] =
    useGetProductReportsLazyQuery({
      variables: {
        input: {
          limit: 10000,
        },
      },
      onError: (error) => {
        message.error(error.message);
      },
    });

  const isLoading = getProductReportQuery.loading;
  const receivedProductsReport = (getProductReportQuery.data
    ?.getProductReports || []) as ProductReportOutput[];

  const mappedProductsReport = receivedProductsReport.map(
    ({ expirationDate, ...restProductData }) => {
      const processor = restProductData.discardedProduct?.processor;
      const processorName = processor
        ? `${processor?.firstName} ${processor?.lastName}`
        : '';
      return {
        ...restProductData,
        processor: processorName,
        discarded: Boolean(restProductData.discardedProduct),
        expirationDate: expirationDate
          ? format(new Date(expirationDate), 'MM/dd/yyyy')
          : '',
      };
    }
  );

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <Button
          onClick={() => getProductReport()}
          loading={isLoading}
          disabled={isLoading}
          type="primary"
        >
          {t('generateCsvReport')}
        </Button>
      </Col>
      <Col span={24}>
        {Boolean(receivedProductsReport.length) && (
          <CSVLink
            headers={donorReportHeaders}
            data={mappedProductsReport}
            filename={fileName}
          >
            {`${fileName}.csv`}
          </CSVLink>
        )}
      </Col>
    </Row>
  );
};

export default ProductReports;
