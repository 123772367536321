import { SelectProps } from 'antd';
import config from 'config';
import { Select } from 'modules/common/components';
import styles from 'modules/common/components/Select/styles.module.scss';
import { useDebounce } from 'modules/common/hooks';
import { ComponentProps, FC, useEffect } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import {
  OwnerType,
  useGetUsersListLazyQuery,
  useGetUsersListQuery,
  UserRole,
} from 'types.d';

type PropTypes = {
  userRoles?: UserRole[];
  ownerTypes?: OwnerType[];
  isArchived?: boolean;
  controlled: UseControllerProps<any>;
  productRegionPreferenceId?: string;
} & Omit<ComponentProps<typeof Select>, 'options'>;

const UserSelect: FC<PropTypes> = ({
  userRoles,
  ownerTypes,
  controlled,
  className,
  isArchived,
  productRegionPreferenceId,
  ...restProps
}) => {
  const classNames = [styles.select, className || ''].join(' ');
  const controller = useController(controlled);
  const { clearDebounce, debounce } = useDebounce();
  const [getUsersByIds, getUsersByIdsQuery] = useGetUsersListLazyQuery();
  const getUsersListQuery = useGetUsersListQuery({
    variables: {
      input: {
        filter: {
          roles: userRoles,
          ownerTypes,
          isArchived,
          productRegionPreferenceId,
        },
        take: config.DEFAULT_LIMIT,
      },
    },
  });
  const fetchedUsers = [
    ...(getUsersListQuery.data?.getUsersList.data || []),
    ...(getUsersByIdsQuery.data?.getUsersList.data || []),
  ];
  const userList: SelectProps['options'] = fetchedUsers.map((user) => {
    return {
      value: user.id,
      label: (
        <>
          {user.firstName} <b>{user.lastName}</b>
        </>
      ),
    };
  });

  useEffect(() => {
    if (!Array.isArray(controller.field.value)) {
      return;
    }

    const unknownCurrentValue = controller.field.value?.filter(
      (userId: string) => !userList?.find((user) => user.value === userId)
    );
    if (!unknownCurrentValue?.length) {
      return;
    }

    getUsersByIds({
      variables: {
        input: {
          filter: {
            ids: unknownCurrentValue,
          },
        },
      },
    });
  }, [controller.field.value]);

  const handleSearch = (value: string) => {
    getUsersListQuery.refetch({
      input: {
        filter: {
          username: value,
          roles: userRoles,
          isArchived,
        },
      },
    });
  };

  useEffect(() => clearDebounce, []);

  return (
    <Select
      options={userList}
      {...restProps}
      className={classNames}
      showSearch
      adjustDropdownPosition
      showArrow
      loading={getUsersListQuery.loading || getUsersByIdsQuery.loading}
      filterOption={false}
      onSearch={debounce(handleSearch)}
      defaultActiveFirstOption={false}
      {...controller.field}
    />
  );
};

export default UserSelect;
