import {
  anticoagulantSchema,
  discardReasonSchema,
  processingProductTypeSchema,
} from 'modules/products/schemas';
import { Anticoagulant, ReasonDiscardProduct } from 'types.d';
import * as yup from 'yup';

export type CreateProductSchema = {
  woocommerceCategoryId: number;
  quantity?: number;
  woocommerceProductId: number;
  anticoagulant?: Anticoagulant;
  storageRequirements: string[];
  values: number[];
  discarded?: boolean;
  processingType?: string;
  processorId?: string;
  discardedReason?: string;
  discardReasonDetails?: string;
};

export const createProductSchema: yup.SchemaOf<CreateProductSchema> =
  yup.object({
    woocommerceCategoryId: yup.number().required(),
    quantity: yup.number().when('discarded', {
      is: false,
      then: () => yup.number().required(),
    }),
    woocommerceProductId: yup.number().required(),
    anticoagulant: anticoagulantSchema.when('discarded', {
      is: false,
      then: () => anticoagulantSchema.required(),
    }),
    storageRequirements: yup.array().of(yup.string().required()),
    values: yup
      .array()
      .of(yup.number().required())
      .required()
      .when(['quantity'], (quantity) => {
        return yup
          .array()
          .of(yup.number().required())
          .min(quantity)
          .max(quantity);
      })
      .default([]),
    discarded: yup.boolean(),
    processingType: processingProductTypeSchema.when('discarded', {
      is: true,
      then: () => processingProductTypeSchema.required(),
    }),
    processorId: yup
      .string()
      .when('discarded', { is: true, then: () => yup.string().required() }),
    discardedReason: discardReasonSchema.when('discarded', {
      is: true,
      then: () => discardReasonSchema.required(),
    }),
    discardReasonDetails: yup.string().when('discardedReason', {
      is: ReasonDiscardProduct.Other,
      then: () => yup.string().required().min(5).max(500),
    }),
  });
