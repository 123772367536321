import { Col, Row } from 'antd';
import config from 'config';
import { format } from 'date-fns';
import { FormItem } from 'modules/common/components';
import AnticoagulantSelect from 'modules/products/components/AnticoagulantSelect';
import ProductQuantitySelect from 'modules/products/components/ProductQuantitySelect';
import ProductValueFields from 'modules/products/components/ProductValueFields';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DonationOutput } from 'types';

import { CreateProductSchema } from '../../schema';
import styles from '../../styles.module.scss';

type PropTypes = {
  donation: DonationOutput;
};

const DonationDataSection: FC<PropTypes> = ({ donation }) => {
  const { t } = useTranslation('products.CreateProductModal');
  const { control, watch } = useFormContext<CreateProductSchema>();
  const donationDate = donation?.donationDate
    ? new Date(donation?.donationDate)
    : undefined;
  const quantity = watch('quantity');

  return (
    <>
      <Col span={24}>
        <span className={styles.subtitle}>{t('donorId')}</span>{' '}
        {donation.donor.shortId}
      </Col>
      <Col span={24}>
        <span className={styles.subtitle}>{t('bloodType')}</span>{' '}
        {donation.donor.bloodType}
      </Col>
      <Col span={24}>
        <Row gutter={[10, 0]}>
          <Col md={6} xs={24} className={styles.subtitle}>
            {t('quantity')}
          </Col>
          <Col md={6} xs={24}>
            <ProductQuantitySelect controlled={{ control, name: 'quantity' }} />
          </Col>
          <Col md={6} xs={24}>
            <ProductValueFields
              productQuantity={quantity}
              name="values"
              control={control}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <FormItem className={styles.formItem} label="Anticoagulant" colon>
          <AnticoagulantSelect
            controlled={{ control, name: 'anticoagulant' }}
          />
        </FormItem>
      </Col>
      <Col span={24}>
        <span className={styles.subtitle}>{t('donationDate')} </span>
        {donationDate && format(donationDate, config.SHORT_DATE_FORMAT)}
      </Col>
    </>
  );
};

export default DonationDataSection;
