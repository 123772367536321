import { Row, Col } from 'antd';
import config from 'config';
import { format } from 'date-fns';
import ProductExpirationDates from 'modules/products/components/ProductExpirationDates';
import ProductQR from 'modules/products/components/ProductQR';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ProductOutput,
  ProductStatus,
  useGetWoocommerceCategoriesQuery,
} from 'types.d';

import styles from './style.module.scss';

import PrintProductLabels from '../PrintProductLabels';

type PropTypes = {
  product: ProductOutput;
};

const LabelInfo = forwardRef<any, PropTypes>(({ product }: PropTypes, ref) => {
  const { t } = useTranslation('products.LabelInfo');

  const getWoocommerceCategoriesQuery = useGetWoocommerceCategoriesQuery({
    variables: {
      input: {},
    },
  });
  const productCategory =
    getWoocommerceCategoriesQuery.data?.getWoocommerceCategories.find(
      (category) => category.id === product.woocommerceCategoryId
    );
  const isDiscarded = product.status === ProductStatus.Discarded;
  const donorFields: Record<string, string | undefined> = {
    [t('donationId')]: `${product.donation.shortId}`,
    [t('bloodType')]: product.donation.donor.bloodType || '',
    [t('volume')]: `${product.value}`,
    [t('anticoagulant')]: product.anticoagulant || '',
    [t('donationDate')]: format(
      new Date(product.donation.donationDate),
      config.SHORT_DATE_FORMAT
    ),
    [t('productRegion')]: product.regionPreference?.name || '',
  };

  return (
    <Row ref={ref} gutter={[0, 20]}>
      <Col span={24}>
        <Row justify="space-between">
          <Col span={10} className={styles.fieldValue}>
            {productCategory?.name}
          </Col>
          <Col span={14}>
            <ProductExpirationDates
              donationDate={new Date(product.donation.createdAt)}
              productCategoryId={product.woocommerceCategoryId}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row>
          <Col span={18}>
            <Row>
              {Object.keys(donorFields).map((field) => {
                return (
                  <Col key={field} span={24}>
                    {field}:{' '}
                    <span className={styles.fieldValue}>
                      {donorFields[field]}
                    </span>
                  </Col>
                );
              })}
            </Row>
          </Col>
          <Col span={6}>
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <ProductQR width={80} height={80} productId={product.shortId} />
              </Col>
              {!isDiscarded && (
                <Col span={24}>
                  <PrintProductLabels products={[product]} />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
});

export default LabelInfo;
